<template>
  <div id="app" :style="{ background: `url(${url})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }">
    <van-nav-bar
      title=""
      left-arrow
      fixed
      placeholder
      :border="false"
      @click-left="onClickLeft"
    />

    <span class="placeholder"></span>

    <div class="container">
      <p class="contentInfo">
        <span
          style="font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4D4E50;
            line-height: 49px;"
          >美好生活</span
        ><span
          style="font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF3E7D;
          line-height: 49px;margin-left: 9px;">
          尽在Win生活</span
        >
      </p>
      <div class="button">
        <van-button type="primary" @click="goLogin">手机登录</van-button>
        <div
          style="display: flex;
          justify-content: center;font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FE5080; height: 60px;
          line-height: 60px;
          width: 100%;"
          @click="onClickRight">
          新用户注册
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import url from './win.png'
export default {
  data () {
    return {
      url
    }
  },

  methods: {
    onClickLeft() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage('up')
      }
      if (isAndroid) {
        window.android.close()
      }
    },
    onClickRight() {
      if (navigator.userAgent.indexOf('QQTheme') != -1) {
        Toast.fail('请在复制链接在默认浏览器中打开')
        return
      }
      if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
        Toast.fail('请点击右上角按钮在在浏览器中打开')
        return
      }
      this.$router.push({
        path: '/register',
        query: {
          type: this.$route.query.type,
          types: this.$route.query.types,
          userId: this.$route.query.userId,
          invite_code: this.$route.query.invite_code,
        },
      })
    },
    goLogin() {
      if (navigator.userAgent.indexOf('QQTheme') != -1) {
        Toast.fail('请在复制链接在默认浏览器中打开')
        return
      }
      if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
        Toast.fail('请点击右上角按钮在在浏览器中打开')
        return
      }
      this.$router.push({
        path: '/phone',
        query: {
          type: this.$route.query.type,
          userId: this.$route.query.userId,
          invite_code: this.$route.query.invite_code,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
#app {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.placeholder {
  height: 25%;
}
.container {
  background: #fff;
  flex: 1;
  border-radius: 20px 20px 0 0;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
}
.img {
  width: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  margin-top: 60px;
}
.contentInfo {
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    margin: 0 2px;
  }
}
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.van-button--primary {
  width: 343px;
  height: 48px;
  background: #FE5080;
  opacity: 1;
  border-radius: 22px;
  border: none;
  margin-top: 10px;
}
.van-nav-bar {
  background-color: transparent;
}
/deep/.van-nav-bar__left .van-icon-arrow-left {
  color: white;
}
/deep/.van-nav-bar--fixed {
  background-color: transparent;
}
/deep/ .van-nav-bar__text {
  color: black;
}
</style>
